import React from 'react'
import {
  Routes as BrowserRouter,
  Route
} from 'react-router-dom'

import {
  Template,
  Unauthenticated
} from 'components'

import Login from 'pages/Auth/Login'

import NotFound from 'pages/Common/NotFound'
import AccessDenied from 'pages/Common/AccessDenied'

import UsersList from 'pages/Users/List'
import UserForm from 'pages/Users/Form'
import UserGroupList from 'pages/Users/Groups/List'
import UserGroupForm from 'pages/Users/Groups/Form'
import SupplierList from 'pages/Suppliers/List'
import SupplierForm from 'pages/Suppliers/Form'
import ApprovalHierarchyList from 'pages/ApprovalHierarchy/List'
import ApprovalHierarchyCreationForm from 'pages/ApprovalHierarchy/Form/creationForm'
import ApprovalHierarchyDetail from 'pages/ApprovalHierarchy/Detail'
import ApprovalHierarchyEditionForm from 'pages/ApprovalHierarchy/Form/editionForm'
import ProductList from 'pages/Product/List'
import ProductForm from 'pages/Product/Form'
import ClientList from 'pages/Client/List'
import ClientForm from 'pages/Client/Form'
import ProjectList from 'pages/Projects/List'
import ProjectDetail from 'pages/Projects/Detail'
import QuoteList from 'pages/QuotePanel/List'
import QuoteDetail from 'pages/QuotePanel/Detail'
import ApprovalPanel from 'pages/ApprovalPanel'
import ApprovalPanelDetail from 'pages/ApprovalPanel/Detail'
import CompleteRegistration from 'pages/Suppliers/CompleteRegistration'
import PurchaseRequisitionList from 'pages/PurchaseRequisition/List'
import PurchaseRequisitionCreate from 'pages/PurchaseRequisition/Create'
import PurchaseRequsitionForm from 'pages/PurchaseRequisition/Form'
import PurchaseOrderList from 'pages/PurchaseOrder/List'
import PurchaseOrderForm from 'pages/PurchaseOrder/Form'
import RequisitionContract from 'pages/PurchaseRequisition/RequisitionContract'
import Tracker from 'pages/Tracker'
import BusinessUnits from 'pages/BusinessUnits/List'
import BusinessUnitsForm from 'pages/BusinessUnits/Form'
import CostCenters from 'pages/CostCenters/List'
import CostCentersForm from 'pages/CostCenters/Form'
import Requests from 'pages/Requests/List'
import RequestDetail from 'pages/Requests/Detail'
import PurchaseAndHiring from "pages/Requests/PurchaseAndHiring";
import RequestEdition from "pages/Requests/Edition/PurchaseAndHiringFormEdition";
import PurchaseWithApportionment from "../pages/Requests/PurchaseWithApportionment";

const Routes = () => (
  <BrowserRouter>
    <Route element={<Unauthenticated />}>
      <Route
        path='/login'
        element={<Login />}
      />
      <Route
        path='/fornecedores/:id/atualizacao-cadastral'
        element={<CompleteRegistration />}
      />
      <Route
        path='*'
        element={<NotFound />}
      />
      <Route
        path='/acesso-negado'
        element={<AccessDenied />}
      />
    </Route>
    <Route element={<Template />}>
      <Route
        path='/'
        element={<Requests />}
      />
      <Route
        path='requisicao/visualizar/:id'
        element={<RequestDetail />}
      />
      <Route
        path='requisicao/alterar/:id'
        element={<RequestEdition />}
      />
      <Route
        path='/compra-contratacao/atrelada-projeto/nova'
        element={<PurchaseAndHiring />}
      />
      <Route
        path='/rateio-contratos/atrelada-projeto/nova'
        element={<PurchaseWithApportionment />}
      />
      <Route
        path='unidades-de-negocio'
        element={<BusinessUnits />}
      />
      <Route
        path='/unidades-de-negocio/visualizar/:id'
        element={<BusinessUnitsForm />}
      />
      <Route
        path='centros-de-custo'
        element={<CostCenters />}
      />
      <Route
        path='/centros-de-custo/visualizar/:id'
        element={<CostCentersForm />}
      />
      <Route
        path='/usuarios'
        element={<UsersList />}
      />
      <Route
        path='/usuarios/editar/:id'
        element={<UserForm />}
      />
      <Route
        path='/usuarios/visualizar/:id'
        element={<UserForm />}
      />
      <Route
        path='/grupos-usuarios'
        element={<UserGroupList />}
      />
      <Route
        path='/grupos-usuarios/novo'
        element={<UserGroupForm />}
      />
      <Route
        path='/grupos-usuarios/editar/:id'
        element={<UserGroupForm />}
      />
      <Route
        path='/grupos-usuarios/visualizar/:id'
        element={<UserGroupForm />}
      />
      <Route
        path='/clientes'
        element={<ClientList />}
      />
      <Route
        path='/clientes/visualizar/:id'
        element={<ClientForm />}
      />
      <Route
        path='/fornecedores'
        element={<SupplierList />}
      />
      <Route
        path='/fornecedores/novo'
        element={<SupplierForm />}
      />
      <Route
        path='/fornecedores/editar/:id'
        element={<SupplierForm />}
      />
      <Route
        path='/fornecedores/visualizar/:id'
        element={<SupplierForm />}
      />
      <Route
        path='/painel-aprovacoes'
        element={<ApprovalPanel />}
      />
      <Route
        path='/painel-aprovacoes/detalhes/:id'
        element={<ApprovalPanelDetail />}
      />
      <Route
        path='/produtos'
        element={<ProductList />}
      />
      <Route
        path='/produtos/visualizar/:id'
        element={<ProductForm />}
      />
      <Route
        path='/produtos/editar/:id'
        element={<ProductForm />}
      />
      <Route
        path='/projetos'
        element={<ProjectList />}
      />
      <Route
        path='/projetos/detalhes/:id'
        element={<ProjectDetail />}
      />
      <Route
        path='/alcadas'
        element={<ApprovalHierarchyList />}
      />
      <Route
        path='/alcadas/novo'
        element={<ApprovalHierarchyCreationForm />}
      />
      <Route
        path='/alcadas/visualizar/:id'
        element={<ApprovalHierarchyDetail />}
      />
      <Route
        path='/alcadas/editar/:id'
        element={<ApprovalHierarchyEditionForm />}
      />
      <Route
        path='/cotacoes'
        element={<QuoteList />}
      />
      <Route
        path='/cotacoes/detalhes/:id'
        element={<QuoteDetail />}
      />
      <Route
        path='/requisicao-compras'
        element={<PurchaseRequisitionList />}
      />
      <Route
        path='/requisicao-compras/novo'
        element={<PurchaseRequisitionCreate />}
      />
      <Route
        path='/requisicao-compras/visualizar/:id'
        element={<PurchaseRequsitionForm />}
      />
      <Route
        path='/requisicao-compras/editar/:id'
        element={<PurchaseRequsitionForm />}
      />
      <Route
        path='/requisicao-compras/clonar/:id'
        element={<PurchaseRequsitionForm />}
      />
      <Route
        path='/requisicao-compras/contrato/:id'
        element={<RequisitionContract />}
      />
      <Route
        path='/compra-direta'
        element={<PurchaseOrderList />}
      />
      <Route
        path='/compra-direta/novo'
        element={<PurchaseOrderForm />}
      />
      <Route
        path='/compra-direta/visualizar/:id'
        element={<PurchaseOrderForm />}
      />
      <Route
        path='/compra-direta/editar/:id'
        element={<PurchaseOrderForm />}
      />
      <Route
        path='/compra-direta/contrato/:id'
        element={<PurchaseOrderForm />}
      />
      <Route
        path='/acompanhamentos'
        element={<Tracker />}
      />
    </Route>
  </BrowserRouter>
)

export default Routes
