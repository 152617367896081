import React, { useEffect, useState } from "react";
import {
  TApprovalHierarchyForm,
  useApprovalHierarchy,
} from "repositories";
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  SearchField
} from "components";
import { Navigate, useNavigate, useParams } from "react-router";
import {
  Button,
  Form,
  Space,
  Radio,
  Checkbox,
} from "antd";
import { useAuth } from "hooks";
import ApproverList from "../../ApprovalHierarchy/Components/ApproverList";
import ApproverReferenceList from "../../ApprovalHierarchy/Components/ApproverReferenceList";

import ModalChangeHistory from "../../ApprovalHierarchy/Components/ModalChangeHistory";


const initialValues: TApprovalHierarchyForm = {
  id: "",
  requestId: "",
  name: "",
  businessUnits: {},
  timing: "",
  reference: false,
  categoryRequest: [],
  typeRequest: "",
  typeProvider: "",
  approvers: [],
  approversReference: [],
};


const ApprovalHierarchyDetail = () => {
  const [approvalHierarchy, setApprovalHierarchy] = useState<TApprovalHierarchyForm>({} as TApprovalHierarchyForm);
  const { roles: authRoles, hasRole } = useAuth();
  const navigate = useNavigate();
  const { id: IdApprovalHierarchy } = useParams<{ id: string }>();
  const repository = useApprovalHierarchy();
  const [form] = Form.useForm<TApprovalHierarchyForm>();
  const [filesItems, setFilesItems] = useState<any>([]);
  const [approvers, setApprovers] = useState<any>([]);
  const [typeRequest, setTypeRequest] = useState(1);
  const [isVisibleModalChangeHistory, setIsVisibleModalChangeHistory] = useState(false);
  const [timing, setTiming] = useState("");
  const [isReference, setIsReference] = useState(false);
  const [BuName, setBuName] = useState("");


  useEffect(() => {
    const getApprovalHierarchyById = async () => {
      const response = await repository.findByIdApprovalHierarchy(
        Number(IdApprovalHierarchy)
      );
      if (!response) return;
      const approvers = response.approvers.map((approver, index) => ({
        ...approver,
        localId: index + 1,
      }));
      const approversReference = response.approversReference.map(
        (approver, index) => ({
          ...approver,
          localId: index + 1,
        })
      );

      const {
        id,
        name,
        timing,
        businessUnits,
        requestId,
        typeProvider,
        typeRequest,
        reference,
        categoryRequest,
      } = response;

      const data = {
        id,
        name,
        timing,
        businessUnits,
        typeProvider,
        typeRequest,
        reference,
        categoryRequest,
        requestId: requestId ? response.requestId : "",
        selectBu: businessUnits.name,
        approvers,
        approversReference,
      };

      form.setFieldsValue(data);

      setIsReference(data.reference);
      setTiming(data.timing);
      setBuName(response.businessUnits.name);
      setApprovalHierarchy(data);
    };


    form.resetFields();
    if (IdApprovalHierarchy) {
      getApprovalHierarchyById();
    }
  }, [IdApprovalHierarchy]);


  const onClickGoBack = () => {
    navigate("/alcadas");
  };

  if (
    authRoles &&
    !hasRole(
      authRoles?.approvalhierarchy_edit || authRoles?.approvalhierarchy_view
    )
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  const onChangeTypeRequest = (e: any) => {
    setTypeRequest(e.target.value);
  };

  const onChangeTiming = (e: any) => {
    setTiming(e.target.value);
  };

  const onChangeIsReference = (e: any) => {
    setIsReference(e.target.checked);
  };

  const secondOptions = [
    { label: "Pacote", value: 11 },
    { label: "Roteiro", value: 22 },
  ];
  const props = {
    name: "file",
    multiple: true,
    action: "",
    customRequest: (file: any) => {
      setFilesItems([...filesItems, file.file]);
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <PageHeader
        title={`Visualizar Alçada de Aprovação`}
        breadcrumbItems={["Alçadas", "Visualizar"]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={()=>{}}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={12} xxl={12}>
              <TextField required disabled name="id" label="Id" />
            </Col>
            <Col xl={12} xxl={12}>
              <TextField
                required
                disabled
                name="requestId"
                label="ID da requisição"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={14}>
              <TextField
                required
                disabled
                name="name"
                label="Nome"
              />
            </Col>
            <Col xl={14}>
              <Checkbox
                name="reference"
                disabled
                onChange={onChangeIsReference}
                checked={isReference}
              />
              Alçada de Referência
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <Form.Item>
                Parâmetros para configuração de alçada de aprovação de
                requisições
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeTypeRequest}
                  value={typeRequest}
                  disabled
                >
                  <Radio value={1}>Requisições Simples</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={1}>
              <></>
            </Col>
            <Col xl={23}>
              <Form.Item>
                {typeRequest === 1 ? (
                  <Radio.Group
                    value={1}
                    disabled
                  >
                    <Radio value={1}>Compras e contratações</Radio>
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    options={secondOptions}
                    disabled
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeTiming}
                  value={timing}
                  disabled
                >
                  <Radio value="PLANEJADA">Planejada</Radio>
                  <Radio value="EMERGENCIAL">Não planejada</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  value={1}
                  disabled
                >
                  <Radio value={1}>Atrelada a projeto</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <SearchField
                required
                allowClear
                name="selectBu"
                placeholder="Selecione uma unidade de negócio"
                value={BuName}
                disabled
              />
            </Col>
          </Row>
          <ApproverList
            initialApprovers={approvalHierarchy.approvers}
            setApprovers={setApprovers}
            approvers={approvers}
            isView={true}
          />


          {!isReference ? (
            <ApproverReferenceList
              initialApprovers={approvalHierarchy.approversReference}
            />
          ) : (
            <></>
          )}
          <Form.Item className="mt-1">
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
      <ModalChangeHistory
          isVisible={isVisibleModalChangeHistory}
          setIsVisible={setIsVisibleModalChangeHistory}
      />
    </>
  );
};

export default ApprovalHierarchyDetail;
