import React, {useEffect, useState} from "react";
import {Modal, Form, Table, Row, Col, Button} from "antd";
import {AreaField, TextField} from "components";
import ApproverListView from "./ApproverListView";
import {DownloadOutlined} from "@ant-design/icons";
import axios from "../../../services/axios";
import styled from "styled-components";

const initialValues: any = {};


const ShortAreaField = styled(AreaField)`
  textarea {
    height: 80px;
  }
`;

const ModalChangeHistory = (props: any) => {
    const { isVisible, setIsVisible, record } = props;

    const [form] = Form.useForm<any>();
    const [approvalHierarchyUsersList, setApprovalHierarchyUsersList] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const [filesHistory, setFilesHistory] = useState([]);

    useEffect(() => {}, []);

    const handleClose = () => {
        setIsVisible(false);
        form.resetFields();
    };

    useEffect(() => {
        const getlogs = async () => {

            const { register } = record
            const { approvalHierarchyUsers, justifyEdit, responsibleManager, files} = register

            setFilesHistory(files);
            setApprovalHierarchyUsersList(approvalHierarchyUsers);

            form.setFieldValue("justifyEdit", justifyEdit)
            form.setFieldValue("responsibleManager", responsibleManager)
        };

        if(record){
            getlogs();
        }
    }, [record]);

    const downloadArchive = async (fileName: any) => {
        try {
            setDownloadLoading(true);
            const response = await axios.get(
                "/v1/manager/project-request/download/" + fileName,
                {
                    responseType: "arraybuffer",
                }
            );
            const blob = new Blob([response.data], {
                type: "application/octet-stream",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
        } finally {
            setDownloadLoading(false);
        }
    };
    return (
        <Modal
            title=""
            visible={isVisible}
            onOk={()=> {}}
            okText=""
            cancelText="Fechar"
            onCancel={handleClose}
            width={800}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={()=>{}}
                initialValues={initialValues}
            >
                <Row>
                    <Col  style={{ width: "100%" }}>
                        <ApproverListView
                            isView={true}
                            approvers={approvalHierarchyUsersList}
                        />
                    </Col>
                    <Col span={24}>
                        <ShortAreaField
                            name="justifyEdit"
                            maxLength={-1}
                            label="Justifique a necessidade de edição desta alçada de referência."
                            disabled
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            name="responsibleManager"
                            label="Qual foi o gestor que autorizou a alteração desta alçada?"
                            disabled
                        />
                    </Col>
                    <Col span={24}>
                        <Table
                            pagination={false}
                            dataSource={filesHistory}
                            columns={[
                                {
                                    title: "Nome do arquivo",
                                    dataIndex: "fileName",
                                    key: "fileName",
                                },
                                {
                                    title: "Formato",
                                    dataIndex: "type",
                                    key: "type",
                                    render(_: any, record: any) {
                                        return record?.fileName?.split(".").pop();
                                    },
                                },
                                {
                                    title: "",
                                    dataIndex: "Dawnload",
                                    key: "dawnload",
                                    render(_: any, record: any) {
                                        return (
                                            <Button
                                                type="link"
                                                icon={<DownloadOutlined />}
                                                size="small"
                                                onClick={() => downloadArchive(record.fileName)}
                                                loading={downloadLoading}
                                            >
                                                Baixar
                                            </Button>
                                        );
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalChangeHistory;
