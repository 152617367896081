import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Space,
  Table
} from 'antd'
import {
  TApprovalHierarchyUser,
  TUpdateOrderSequenceRequest,
  useApprovalHierarchy
} from 'repositories'
import { ColumnsType } from 'antd/lib/table'
import { usePagination } from 'hooks'
import { formatCurrency } from 'utils/helpers'
import {
  BiDownArrowAlt,
  BiTrash,
  BiUpArrowAlt
} from 'react-icons/bi'
import { useParams } from 'react-router'
import ApproverForm from './ApproverForm'

type TApprovalHierarchyUserData = TApprovalHierarchyUser & {
  key: string
}

const columns: ColumnsType<TApprovalHierarchyUserData> = [
  {
    title: 'Id',
    dataIndex: 'approveSequence',
    key: 'approveSequence'
  },
  {
    title: 'Nome',
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: 'SLA',
    dataIndex: 'sla',
    key: 'sla',
    align: 'center'
  },
  {
    title: 'Valor mínimo',
    dataIndex: 'minimumValue',
    key: 'minimumValue',
    align: 'right',
    render: (_, record) => formatCurrency(record.minimumValue),
  }
]

type TProps = {
  initialApprovers: TApprovalHierarchyUser[]
  isView?: boolean
  setApprovers?: any,
  approvers?: any,
}

const ApproverList = (props: TProps) => {
  const {
    initialApprovers,
    isView = false,
    setApprovers,
    approvers,
  } = props
  const [isVisibleApproverForm, setIsVisibleApproverForm] = useState(false)
  const pagination = usePagination()

  const onSort = (list: TApprovalHierarchyUser[]) => {
    if (!list.length) return []

    return list.sort((old, after) => {
      if (old.approveSequence > after.approveSequence) return 1
      if (old.approveSequence < after.approveSequence) return -1

      return 0
    })
  }

  const updateOrder = async (data: any) => {
    // const response = await approvalHierarchyRepository.updateOrderSequence(data)
    // if (!response) return
    //
    // message.success('Ordem de aprovadores atualizada com sucesso!')
  }

  const onChangeUpSequence = (index: number) => () => {
    const before = approvers[index - 1]
    const current = approvers[index]
    if (!current || !before) return
    const beforeSequence = before.approveSequence
    const currentSequence = current.approveSequence

    if (Number(current.minimumValue) > Number(before.minimumValue)) {
      message.error('Não é possivel ordenar, valor atual é superior ao de cima')
      return
    }

    const mappedApprovers = approvers.map((approve: any ) => {
      const getNewSequence = () => {
        switch (approve.approveSequence) {
          case beforeSequence: return currentSequence
          case currentSequence: return beforeSequence
          default: return approve.approveSequence
        }
      }

      const sequence = getNewSequence()
      return {
        ...approve,
        approveSequence: sequence
      }
    })

    const sort = onSort(mappedApprovers)
    setApprovers(sort)
    const updateOrderSequence = mappedApprovers.map((approve: any) => ({
      approvalId: Number(approve.id),
      approveSequence: Number(approve.approveSequence)
    }))
    updateOrder({
      approvers: updateOrderSequence
    })
  }

  const onChangeDownSequence = (index: number) => () => {
    const after = approvers[index + 1]
    const current = approvers[index]
    if (!current || !after) return
    const AfterSequence = after.approveSequence
    const currentSequence = current.approveSequence

    if (Number(current.minimumValue) < Number(after.minimumValue)) {
      message.error('Não é possivel ordenar, valor atual é superior ao debaixo')
      return
    }

    const mappedApprovers = approvers.map((approve: any) => {
      const getNewSequence = () => {
        switch (approve.approveSequence) {
          case currentSequence: return AfterSequence
          case AfterSequence: return currentSequence
          default: return approve.approveSequence
        }
      }

      const sequence = getNewSequence()
      return {
        ...approve,
        approveSequence: sequence
      }
    })
    const sort = onSort(mappedApprovers)
    setApprovers(sort)
    const updateOrderSequence = mappedApprovers.map((approve: any ) => ({
      approvalId: Number(approve.id),
      approveSequence: Number(approve.approveSequence)
    }))
    updateOrder({
      approvers: updateOrderSequence
    })
  }

  const onAdd = () => setIsVisibleApproverForm(true)

  const onDelete = (approverId: any) => async () => {
    const filteredApprovers = approvers.filter((approve: any ) => approve.userId !== approverId)
    setApprovers(filteredApprovers)
  }

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: (
        <Button
          type='primary'
          disabled={isView || approvers.length === 7}
          onClick={onAdd}
        >
          Adicionar
        </Button>
      ),
      key: 'action',
      render: (_: any, record: any, index: any) => {
        const currentIndex = index + 1

        return (
          <Space size='small'>
            <Button
              disabled={isView || record.approveSequence === 1}
              onClick={onChangeUpSequence(index)}
              size='small'
              type='text'
            >
              <BiUpArrowAlt size={18} />
            </Button>
            <Button
              onClick={onChangeDownSequence(index)}
              disabled={isView || currentIndex === approvers.length}
              size='small'
              type='text'
            >
              <BiDownArrowAlt size={18} />
            </Button>
            <Button
              onClick={onDelete(Number(record.userId))}
              disabled={isView}
              size='small'
              type='text'
            >
              <BiTrash size={18} />
            </Button>
          </Space>
        )
      },
    }
  ], [approvers, isView])

  useEffect(() => {
    const formattedInitialApprovers = initialApprovers?.map(initialApprover => ({
      ...initialApprover,
      key: String(initialApprover.id),
    })) || []
    setApprovers(formattedInitialApprovers)
  }, [initialApprovers])

  return (
    <>
      <Row>
        <Col xs={24} xl={16}>
          <Divider plain orientation='left'>Aprovadores</Divider>
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={16}>
          <Table
            size='middle'
            columns={updatedColumns}
            dataSource={approvers}
            pagination={pagination.data}
          />
        </Col>
      </Row>
      <ApproverForm
        setApprovers={setApprovers}
        approvers={approvers}
        approverSize={approvers.length || 0}
        isVisibleModal={isVisibleApproverForm}
        setIsVisibleModal={setIsVisibleApproverForm}
      />
    </>
  )
}

export default ApproverList
