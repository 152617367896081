import React from 'react'
import {
    Col,
    Divider,
    Row,
    Table
} from 'antd'
import {
    TApprovalHierarchyUser,
} from 'repositories'
import { ColumnsType } from 'antd/lib/table'
import { usePagination } from 'hooks'
import { formatCurrency } from 'utils/helpers'

type TApprovalHierarchyUserData = TApprovalHierarchyUser & {
    key: string
}

const columns: ColumnsType<TApprovalHierarchyUserData> = [
    {
        title: 'Id',
        dataIndex: 'approveSequence',
        key: 'approveSequence'
    },
    {
        title: 'Nome',
        dataIndex: 'userName',
        key: 'userName'
    },
    {
        title: 'SLA',
        dataIndex: 'sla',
        key: 'sla',
        align: 'center'
    },
    {
        title: 'Valor mínimo',
        dataIndex: 'minimumValue',
        key: 'minimumValue',
        align: 'right',
        render: (_, record) => formatCurrency(record.minimumValue),
    }
]

type TProps = {
    approvers: any
    isView?: boolean
}

const ApproverListView = (props: TProps) => {
    const {
        approvers,
        isView = false,
    } = props

    const pagination = usePagination()
    return (
        <>
            <Row>
                <Col>
                    <Divider plain orientation='left'>Aprovadores</Divider>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        size='middle'
                        columns={columns}
                        dataSource={approvers}
                        pagination={pagination.data}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ApproverListView
