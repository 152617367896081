import React, { useState } from "react";
import type { Rule } from "antd/lib/form";
import {
  TApprovalHierarchyForm,
  useApprovalHierarchy,
  useBusinessUnits,
} from "repositories";
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  SearchField,
} from "components";
import { Navigate, useNavigate } from "react-router";
import {
  Button,
  Form,
  message,
  Space,
  Radio,
  Checkbox,
} from "antd";
import { useAuth } from "hooks";
import ApproverList from "../../Components/ApproverList";
import ModalChangeHistory from "../../Components/ModalChangeHistory";


const initialValues: TApprovalHierarchyForm = {
  id: "",
  requestId: "",
  name: "",
  businessUnits: {},
  timing: "",
  reference: false,
  categoryRequest: [],
  typeRequest: "",
  typeProvider: "",
  approvers: [],
  approversReference: [],
};

const validationForm = (data: any) => {
  let ceo = false;
  let conselho = false;
  if(data.bu === 0){
    message.error(buMessageError);
    return false;
  }
  if(data.hierarchyUsers.approvers.length === 0){
    message.error(hierarchyUsersEmptyMessageError);
    return false;
  }
  if(data.hierarchyUsers.approvers.length === 1){
    message.error(hierarchyUsersOnlyOneMessageError);
    return false;
  }
  for (const item of data.approvers) {
    if (item.userName === "CEO") {
      ceo = true;
    }
    if (item.userName === "Conselho") {
      conselho = true;
    }
    if (conselho && ceo) {
      break;
    }
  }
  if (conselho && ceo) {

  } else if (ceo) {
    message.error(onlyCEOOrOnlyConselho);
    return  false
  } else if (conselho) {
    message.error(onlyCEOOrOnlyConselho);
    return  false
  } else {
    message.error(onlyCEOOrOnlyConselho);
    return  false
  }
  if(data.approvers.length < 3){
    message.error(hierarchyUsersOnlyOneApproverMessageError);
    return false;
  }

  return true;
}

const validations: Record<string, Rule[]> = {
  name: [
    {
      required: true,
      message: 'Campo "Nome" é obrigatório',
    },
    {
      max: 150,
      message: "Nome não pode ser maior que 150 caracteres",
    },
  ],
  selectBu: [
    {
      required: true,
      message: 'Campo "Unidade de negócio" é obrigatório',
    },
    {
      min: 3,
      message: "Unidade de negócio não pode ser menor que 3 caracteres"
    }
  ],
};

const createMessageSuccess = "Alçada criada com sucesso!";
const buMessageError = "Unidade de negocio não selecionada!";
const hierarchyUsersEmptyMessageError = "É obrigatório informar os aprovadores!";
const hierarchyUsersOnlyOneMessageError = "O CEO e Conselho são obrigatórios!";
const hierarchyUsersOnlyOneApproverMessageError = "É obrigatório ao menos um aprovador além do CEO e Conselho";
const onlyCEOOrOnlyConselho = "O CEO e Conselho são obrigatórios.";

const ApprovalHierarchyCreationForm = () => {
  const [approvalHierarchy, setApprovalHierarchy] = useState<TApprovalHierarchyForm>({} as TApprovalHierarchyForm);

  const { roles: authRoles, hasRole } = useAuth();
  const navigate = useNavigate();
  const repository = useApprovalHierarchy();
  const buRepository = useBusinessUnits();
  const [form] = Form.useForm<TApprovalHierarchyForm>();
  const [filesItems, setFilesItems] = useState<any>([]);
  const [approvers, setApprovers] = useState<any>([]);
  const [typeRequest, setTypeRequest] = useState(1);
  const [isVisibleModalChangeHistory, setIsVisibleModalChangeHistory] =
      useState(false);
  const [timing, setTiming] = useState("PLANEJADA");
  const [bus, setBUs] = useState([]);
  const [optionsBU, setBUOptions] = useState([]);
  const [isReference, setIsReference] = useState(true);
  const [linkedRequest, setLinkedRequest] = useState(1);

  const [bu, setBu] = useState<any>(0);
  const [BuName, setBuName] = useState("");

  const onChangeBU = (newValue: any) => {
    const _bu = bus
      .map((bu: any) => ({ id: bu.id, name: bu.name }))
      .filter((bu: any) => bu.name === newValue);
    setBu(_bu[0].id);
  };

  const onSearchBU = async (searchText: string) => {
    const response = await buRepository.findBus({
      name: searchText,
      page: 0,
      size: 10,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.name }));
    setBUOptions(mapped);
    setBUs(response.content);
  };


  const onFinish = async (values: any) => {
    const hierarchyUsers = {approvers: approvers.map((item: any) => {
      const sanitizedItem = { ...item };
      delete sanitizedItem.userName;
      return sanitizedItem
    })}

    let response = {} as any

    const data = {
      bu: bu,
      hierarchyUsers,
      approvers
    }
    if(!validationForm(data)) return ;

    response = await repository.createApprovalHierarchy({
      name: values.name,
      businessUnits: bu,
      timing: timing,
      reference: isReference,
      categoryRequest: "['Compras e contratações']",
      typeRequest: typeRequest === 1 ? "SIMPLE" : "COMPOSED",
      hierarchyUsers,
    });

    if(response.id > 0){
      message.success(createMessageSuccess);
      onClickGoBack();
    }
    if (!response) return;
  };

  const onClickGoBack = () => {
    navigate("/alcadas");
  };

  if (
    authRoles &&
    !hasRole(
      authRoles?.approvalhierarchy_edit || authRoles?.approvalhierarchy_view
    )
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  const onChangeTypeRequest = (e: any) => {
    setTypeRequest(e.target.value);
  };

  const onChangeTiming = (e: any) => {
    setTiming(e.target.value);
  };

  const onChangeIsReference = (e: any) => {
    setIsReference(e.target.checked);
  };

  const onChangeLinkedRequest = (e: any) => {
    setLinkedRequest(e.target.value);
  };

  const secondOptions = [
    { label: "Pacote", value: 11 },
    { label: "Roteiro", value: 22 },
  ];
  const props = {
    name: "file",
    multiple: true,
    action: "",
    customRequest: (file: any) => {
      setFilesItems([...filesItems, file.file]);
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <PageHeader
        title={`Nova Alçada de Aprovação`}
        breadcrumbItems={["Alçadas", "Nova"]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={14}>
              <TextField
                required
                rules={validations.name}
                name="name"
                label="Nome"
              />
            </Col>
            <Col xl={14}>
              <Checkbox
                name="reference"
                onChange={onChangeIsReference}
                checked={isReference}
                disabled
              />
              Alçada de Referência
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <Form.Item>
                Parâmetros para configuração de alçada de aprovação de
                requisições
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeTypeRequest}
                  value={typeRequest}
                >
                  <Radio value={1}>Requisições Simples</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={1}>
              <></>
            </Col>
            <Col xl={23}>
              <Form.Item>
                {typeRequest === 1 ? (
                  <Radio.Group
                    value={1}
                  >
                    <Radio value={1}>Compras e contratações</Radio>
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    options={secondOptions}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeTiming}
                  value={timing}
                >
                  <Radio value="PLANEJADA">Planejada</Radio>
                  <Radio value="EMERGENCIAL">Não planejada</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeLinkedRequest}
                  value={linkedRequest}
                >
                  <Radio value={1}>Atrelada a projeto</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <Form.Item
              >
                <SearchField
                    onSearch={onSearchBU}
                    onChange={onChangeBU}
                    required={true}
                    allowClear
                    name="selectBu"
                    placeholder="Selecione uma unidade de negócio"
                    options={optionsBU}
                    value={BuName}
                />
              </Form.Item>
            </Col>
          </Row>
          <ApproverList
            initialApprovers={approvalHierarchy.approvers}
            setApprovers={setApprovers}
            approvers={approvers}
          />
          <Form.Item className="mt-1">
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
      <ModalChangeHistory
          isVisible={isVisibleModalChangeHistory}
          setIsVisible={setIsVisibleModalChangeHistory}
      />
    </>
  );
};

export default ApprovalHierarchyCreationForm;
